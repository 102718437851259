import * as classNames from 'classnames'
import * as React from 'react'
import {MobileMonthlyDayProps} from '.'
import {MobileMonthlyEvent} from './event'
import * as s from './mobile-monthly-day.scss'

export class MobileMonthlyDay extends React.PureComponent<MobileMonthlyDayProps> {
  render() {
    const {
      borderStyleRight,
      borderStyleBottom,
      dayWithEvents: {day, date, events, past, today},
    } = this.props

    return (
      <div className={s.content} data-hook={`calendar-day-${day}`}>
        <div className={classNames(s.day, {[s.past]: past, [s.today]: today})} style={borderStyleRight}>
          <div className={s.dayText} data-hook={`day-of-month-${day}`}>
            {day}
          </div>
        </div>
        <div className={s.events}>
          {events.map(event => (
            <div key={event.id} className={s.event} style={borderStyleBottom}>
              <MobileMonthlyEvent event={event} date={date} />
            </div>
          ))}
        </div>
      </div>
    )
  }
}
