import {withNamespaces} from 'react-i18next'
import {isEditor, isFullWidth, isSite} from '../../../commons/selectors/environment'
import {getCardsPerRow, getCardBorderWidth, getCardMargins, getImageRatio} from '../../../commons/selectors/settings'
import {getEvents, getFirstEvent} from '../../selectors/events'
import {isItemOpened} from '../../selectors/layout'
import {isAdditionalComponentsHidden, isImageVisible, isMembersEnabled} from '../../selectors/list-settings'
import {getComponentConfig} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Cards as CardsPresentation} from './cards'
import {CardsOwnProps, CardsStateProps} from './interfaces'

const mapRuntimeToProps = ({state, host: {dimensions}}: AppProps): CardsStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    events: getEvents(state),
    showImage: isImageVisible(state),
    showMembers: isMembersEnabled(state),
    hideAdditionalComponents: isAdditionalComponentsHidden(state),
    columns: getCardsPerRow(componentConfig),
    cardMargins: getCardMargins(componentConfig),
    cardBorderWidth: getCardBorderWidth(componentConfig),
    imageRatio: getImageRatio(getComponentConfig(state)),
    expand: isItemOpened(state, getFirstEvent(state).id),
    editor: isEditor(state),
    site: isSite(state),
    fullWidth: isFullWidth(state, dimensions),
  }
}

export const Cards = connect<CardsOwnProps, CardsStateProps>(mapRuntimeToProps)(withNamespaces()(CardsPresentation))
export * from './interfaces'
