import {EventType, RegistrationStatus} from '@wix/wix-events-commons-statics/dist/types/enums/events'
import * as _ from 'lodash'
import {getListButtonText, getOneButtonText, getRSVPClosedText, getWidgetType} from '../../commons/selectors/settings'
import {State} from '../types/state'
import {isForceShowRibbons} from './layout'
import {getComponentConfig, isOfferRibbonVisible, isRibbonVisible, isStatusRibbonVisible} from './settings'

export const getEventById = (state: State, eventId: string) => getEvents(state).find(event => event.id === eventId)

export const getEvents = (state: State): ExtendedEvent[] => {
  if (state.siteSettings.firstEventCreated || state.events.length) {
    return state.events
  }

  return state.demoEvents[getWidgetType(getComponentConfig(state))] as ExtendedEvent[]
}

export const getFirstEvent = (state: State) => getEvents(state)[0]

export const getEventsIds = (state: State) => getEvents(state).map(event => event.id)

const RIBBON_TEXTS = {
  [EventType.TICKETS]: {
    [RegistrationStatus.CLOSED]: 'ribbon.soldOut',
    [RegistrationStatus.CLOSED_MANUALLY]: 'ribbon.soldOut',
  },
  [EventType.RSVP]: {
    [RegistrationStatus.CLOSED]: 'ribbon.rsvpClosed',
    [RegistrationStatus.CLOSED_MANUALLY]: 'ribbon.rsvpClosed',
    [RegistrationStatus.OPEN_RSVP_WAITLIST]: 'ribbon.joinWaitList',
  },
}

const RIBBON_TEXT_FALLBACKS = {
  [EventType.TICKETS]: 'ribbon.soldOut',
  [EventType.RSVP]: 'ribbon.rsvpClosed',
}

export const getRibbonData = (state: State, event: ExtendedEvent) => {
  if (!isRibbonVisible(state)) {
    return {visible: false}
  }

  if (isForceShowRibbons(state)) {
    if (isStatusRibbonVisible(state)) {
      return {
        visible: true,
        text: _.get(RIBBON_TEXT_FALLBACKS, event.registration.type),
        status: true,
      }
    } else if (isOfferRibbonVisible(state)) {
      return {
        visible: true,
        text: 'ribbon.memberOffer',
        status: false,
      }
    }
  }

  const statusText = isStatusRibbonVisible(state)
    ? _.get(RIBBON_TEXTS, `${event.registration.type}.${event.registration.status}`)
    : ''
  const offerText = isOfferRibbonVisible(state) && event.memberOffer ? 'ribbon.memberOffer' : ''

  return {
    text: statusText || offerText,
    visible: Boolean(statusText || offerText),
    status: Boolean(statusText),
  }
}

export const getRSVPButtonTextForSingleEvent = (state: State, event: ExtendedEvent) => {
  const componentConfig = getComponentConfig(state)
  return getRibbonData(state, event).visible ? getRSVPClosedText(componentConfig) : getOneButtonText(componentConfig)
}

export const getRSVPButtonTextForEventListItem = (state: State, event: ExtendedEvent) => {
  const componentConfig = getComponentConfig(state)
  return getRibbonData(state, event).visible ? getRSVPClosedText(componentConfig) : getListButtonText(componentConfig)
}
