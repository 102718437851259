import * as React from 'react'
import {EventInfoProps} from '.'
import {BrowserOnly} from '../../../../../../../commons/components/browser-only'
import * as s from './day-events.scss'

export class DayEvents extends React.PureComponent<EventInfoProps> {
  render() {
    const {events, firstEventTime, t} = this.props

    if (!events.length) {
      return null
    }

    return (
      <div className={s.info}>
        <div className={s.time} data-hook="cell-event-time">
          <BrowserOnly>{firstEventTime}</BrowserOnly>
        </div>
        <div className={s.title}>
          <div className={s.ellipsis} data-hook="cell-event-title">
            {events[0].title}
          </div>
        </div>
        {events.length > 1 ? (
          <div className={s.more} data-hook="more-link">
            {t('layout.calendar.monthly.events.moreLink', {amount: events.length - 1})}
          </div>
        ) : null}
      </div>
    )
  }
}
