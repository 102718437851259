import {TranslationFunction} from 'i18next'
import {isDateVisible} from '../../../../selectors/list-settings'
import {renderIfVisible} from '../../../hoc/render-if-visible'
import {Date as DatePresentation} from './date'

export interface DateProps {
  event: wix.events.Event
  t: TranslationFunction
  fullLocale: string
  customColorClass?: string
}

export const Date = renderIfVisible<DateProps>(DatePresentation, isDateVisible)
