import {callAPI, createActions} from '../../commons/actions/data-action-helper'

export const CREATE_EVENT = createActions('CREATE_EVENT')
export const SHARE_EVENT = 'SHARE_EVENT'
export const GET_EVENT_OFFERS = createActions('GET_EVENT_OFFERS')

export const createEvent = (data: {event: wix.events.EventData; language: string}, ADI: boolean = false) =>
  callAPI(CREATE_EVENT, data, ADI)

export const getEventOffers = (eventIds: string[]) => callAPI(GET_EVENT_OFFERS, eventIds)

export const shareEvent = (eventId: string, buttonName: string) => ({
  type: SHARE_EVENT,
  payload: {
    eventId,
    buttonName,
  },
})
