import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {ExperimentsBag} from '@wix/wix-experiments'
import {WIDGET_TYPE} from '../constants/settings'

export interface CommonState {
  siteSettings: SiteSettings
  environment: EnvironmentConfig
  experiments: ExperimentsBag
  multilingual: Multilingual
}

export interface Multilingual {
  isEnabled: boolean
  currentLanguage?: string | null
}

export interface SiteSettingsSettings {
  version?: number
  defaultWidgetType?: string
  firstTimeCompleted?: boolean
  pageEnabled?: boolean
  pagesType?: PagesType
  pageUpgradeShown?: boolean
  upgradePending?: boolean
  membersPromoCanceled?: boolean
}

export interface SiteSettings extends wix.events.editor.SiteSettings {
  settings: SiteSettingsSettings
  demo: boolean
}

export interface EnvironmentConfig {
  mobile: boolean
  locale: string
  language: string
  timezone: string
  editor: boolean
  site: boolean
  preview: boolean
}

export interface DemoEvents {
  [WIDGET_TYPE.SINGLE]: wix.events.Event[]
  [WIDGET_TYPE.LIST]: wix.events.Event[]
}

export enum PagesType {
  DETAILS_PAGE_ENABLED = 1,
  DETAILS_PAGE_DISABLED = 2,
}

export enum ModalActions {
  CANCEL = 'CANCEL',
  OK = 'OK',
  OUTSIDE_CLICKED = 'OUTSIDE_CLICKED',
}

export type GetCommonState = () => CommonState

export interface CommonStoreExtraArgs {
  wixCodeApi: IWixAPI
}
