import {withNamespaces} from 'react-i18next'
import {getFullLocale, isEditor, isMobile} from '../../../commons/selectors/environment'
import {
  getAlignment,
  getCompactHorizontalDividerHeight,
  getCompactHorizontalDividerWidth,
  getContentAlignment,
  getImagePosition,
  getImageWidth,
  getVerticalImagePosition,
  getWidgetLayout,
  isDateEnabled,
  isHorizontalLayout,
  isLocationEnabled,
  isVerticalLayout,
} from '../../../commons/selectors/settings'
import {getFirstEvent} from '../../selectors/events'
import {
  isCompactDividerVisible,
  isImageVisible,
  isOnlyButtonVisible,
  isOnImageLayout,
  isRibbonVisible,
} from '../../selectors/settings'
import {getComponentConfig} from '../../selectors/settings'
import {resizeToDefault} from '../../services/resize'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {SingleEventOwnProps, SingleEventStateProps} from './interfaces'
import {SingleEvent as SingleEventPresentation} from './single-event'

const mapState = ({state, host: {dimensions}, isRTL}: AppProps): SingleEventStateProps => {
  const componentConfig = getComponentConfig(state)
  const event = getFirstEvent(state)

  return {
    event,
    layout: getWidgetLayout(componentConfig),
    alignment: getAlignment(componentConfig),
    verticalAlignment: getContentAlignment(componentConfig),
    showDate: isDateEnabled(componentConfig),
    showLocation: isLocationEnabled(componentConfig),
    compactDividerVisible: isCompactDividerVisible(state),
    compactDividerWidth: getCompactHorizontalDividerWidth(componentConfig),
    compactDividerHeight: getCompactHorizontalDividerHeight(componentConfig),
    buttonLayout: isOnlyButtonVisible(state),
    onImageLayout: isOnImageLayout(state),
    horizontalLayout: isHorizontalLayout(componentConfig),
    verticalLayout: isVerticalLayout(componentConfig),
    imageVisible: isImageVisible(state),
    imageWidth: getImageWidth(componentConfig),
    imagePosition: getImagePosition(componentConfig),
    verticalImagePosition: getVerticalImagePosition(componentConfig),
    mobile: isMobile(state),
    editor: isEditor(state),
    fullLocale: getFullLocale(state),
    height: dimensions.height,
    width: dimensions.width,
    rtl: isRTL,
    resizeToDefault: layout => resizeToDefault(state, layout),
    showRibbon: isRibbonVisible(state),
  }
}

export const SingleEvent = connect<SingleEventOwnProps, SingleEventStateProps>(mapState)(
  withNamespaces()(SingleEventPresentation),
)
export * from './interfaces'
