import * as classNames from 'classnames'
import * as React from 'react'
import {DividerProps} from '.'
import * as s from './divider.scss'

export const Divider = ({width, height, alignmentClassName, onImage}: DividerProps) => (
  <div
    className={classNames(s[alignmentClassName], onImage ? s.onImage : s.offImage)}
    data-hook="divider"
    style={{width, height}}
  />
)
