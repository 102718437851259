import * as classNames from 'classnames'
import * as React from 'react'
import {RibbonProps} from '.'
import * as s from './ribbon.scss'

export const Ribbon: React.FC<RibbonProps> = ({visible, text, status, t, className}: RibbonProps) =>
  visible ? (
    <div className={className}>
      <div className={classNames(s.root, status ? s.status : s.offer)} data-hook="ribbon">
        <div className={s.text}>{t(text)}</div>
      </div>
    </div>
  ) : null
