import * as classNames from 'classnames'
import * as React from 'react'
import {EmptyStateProps} from '.'
import {Resizable} from '../../../commons/components/hoc/resizable'
import * as scs from '../cards/card/card.scss'
import * as cs from '../cards/cards.scss'
import * as ls from '../list-layout/list-layout.scss'
import * as ss from '../single-event/single-event.scss'
import * as ts from '../single-event/title/title.scss'
import * as s from './empty-state.scss'
import {resizeContainer} from './utils'

export class EmptyState extends React.Component<EmptyStateProps> {
  render() {
    const {singleEventWidget, listLayout, t} = this.props

    return (
      <Resizable resizeFunc={resizeContainer}>
        <div
          className={classNames(s.container, {
            [ss.fullLayout]: singleEventWidget,
            [ls.listContainer]: !singleEventWidget && listLayout,
            [cs.card]: !singleEventWidget && !listLayout,
            [ts.root]: singleEventWidget,
            [scs.evCardContentFont]: !singleEventWidget,
            [scs.evCardContentColor]: !singleEventWidget,
          })}
        >
          {t('widgetEmptyStateNotification')}
        </div>
      </Resizable>
    )
  }
}
