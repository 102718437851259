import {SHARE_BUTTON_NAMES} from '@wix/wix-events-commons-statics'
import * as classNames from 'classnames'
import * as React from 'react'
import {KeyboardEvent} from 'react'
import {SocialBarProps} from '.'
import Facebook from '../../../icons/dist/components/Facebook'
import FacebookNew from '../../../icons/dist/components/FacebookNew'
import Linkedin from '../../../icons/dist/components/Linkedin'
import LinkedinNew from '../../../icons/dist/components/LinkedinNew'
import Twitter from '../../../icons/dist/components/Twitter'
import TwitterNew from '../../../icons/dist/components/TwitterNew'
import {SocialNetworks} from '../../services/share'
import * as s from './social-bar.scss'

const DEFAULT_SPACING = 14

const REFERENCE_FONT_SIZE = 22

export class SocialBar extends React.Component<SocialBarProps> {
  facebookShare = () => {
    const {share, onShare, eventId, eventUrl, t} = this.props
    share(eventUrl, SocialNetworks.Facebook, t)
    onShare && onShare(eventId, SHARE_BUTTON_NAMES.FACEBOOK)
  }

  twitterShare = () => {
    const {share, onShare, eventId, eventUrl, t} = this.props
    share(eventUrl, SocialNetworks.Twitter, t)
    onShare && onShare(eventId, SHARE_BUTTON_NAMES.TWITTER)
  }

  linkedInShare = () => {
    const {share, onShare, eventId, eventUrl, t} = this.props
    share(eventUrl, SocialNetworks.LinkedIn, t)
    onShare && onShare(eventId, SHARE_BUTTON_NAMES.LINKEDIN)
  }

  handleKeyPress = (event: KeyboardEvent<HTMLAnchorElement>, handler: Function) => {
    if (event.key === 'Enter') {
      handler()
    }
  }

  render() {
    const {t, hideTitle, spacing = DEFAULT_SPACING, row, className, iconFontSize, newIcons} = this.props

    return (
      <div data-hook="social-bar" className={classNames({[s.row]: row}, className)}>
        {!hideTitle && <div className={row ? s.rowTitle : s.title}>{t('shareEventText')}</div>}
        <div className={s.icons}>
          <a
            className={classNames(s.icon, 'a11yOutline')}
            data-hook="social-bar-icon-facebook"
            onClick={this.facebookShare}
            onKeyPress={event => {
              this.handleKeyPress(event, this.facebookShare)
            }}
            tabIndex={0}
            aria-label={t('a11y.shareButtonLabel', {button: 'Facebook'})}
          >
            <FacebookIcon iconFontSize={iconFontSize} newIcons={newIcons} />
          </a>
          <a
            style={{margin: `0 ${spacing}px`}}
            className={classNames(s.icon, 'a11yOutline')}
            data-hook="social-bar-icon-twitter"
            onClick={this.twitterShare}
            onKeyPress={event => {
              this.handleKeyPress(event, this.twitterShare)
            }}
            tabIndex={0}
            aria-label={t('a11y.shareButtonLabel', {button: 'Twitter'})}
          >
            <TwitterIcon iconFontSize={iconFontSize} newIcons={newIcons} />
          </a>
          <a
            className={classNames(s.icon, 'a11yOutline')}
            data-hook="social-bar-icon-linkedin"
            onClick={this.linkedInShare}
            onKeyPress={event => {
              this.handleKeyPress(event, this.linkedInShare)
            }}
            tabIndex={0}
            aria-label={t('a11y.shareButtonLabel', {button: 'LinkedIn'})}
          >
            <LinkedinIcon iconFontSize={iconFontSize} newIcons={newIcons} />
          </a>
        </div>
      </div>
    )
  }
}

const getIconSize = (defaultActualSize: number, requiredSize?: number) =>
  requiredSize ? Math.round(((defaultActualSize * requiredSize) / REFERENCE_FONT_SIZE) * 100) / 100 : defaultActualSize

const LinkedinIcon = ({newIcons, iconFontSize}) => {
  if (newIcons) {
    return (
      <LinkedinNew
        size={undefined}
        width={`${getIconSize(24, iconFontSize)}px`}
        height={`${getIconSize(24, iconFontSize)}px`}
      />
    )
  } else {
    return (
      <Linkedin
        size={undefined}
        width={`${getIconSize(19, iconFontSize)}px`}
        height={`${getIconSize(16, iconFontSize)}px`}
      />
    )
  }
}

const TwitterIcon = ({newIcons, iconFontSize}) => {
  if (newIcons) {
    return (
      <TwitterNew
        size={undefined}
        width={`${getIconSize(24, iconFontSize)}px`}
        height={`${getIconSize(24, iconFontSize)}px`}
      />
    )
  } else {
    return (
      <Twitter
        size={undefined}
        width={`${getIconSize(18, iconFontSize)}px`}
        height={`${getIconSize(14, iconFontSize)}px`}
      />
    )
  }
}

const FacebookIcon = ({newIcons, iconFontSize}) => {
  if (newIcons) {
    return (
      <FacebookNew
        size={undefined}
        width={`${getIconSize(24, iconFontSize)}px`}
        height={`${getIconSize(24, iconFontSize)}px`}
      />
    )
  } else {
    return (
      <Facebook
        size={undefined}
        width={`${getIconSize(9, iconFontSize)}px`}
        height={`${getIconSize(16, iconFontSize)}px`}
      />
    )
  }
}
